/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import Modal from 'react-modal';
import Popup from './Popup';

function Footer() {
  const currentYear = new Date().getFullYear();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("Coming Soon");

  const openPopup = (content) => {
    setIsPopupOpen(true);

    let popupContent = ""; // Default content

    if (content === "Version") {
      popupContent = (
        <>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <h2 style={{ margin: '0', marginRight: '10px' }}>
              Version: 2.1.1, Nov 2023
            </h2>
            <div style={versionBoxStyle}>
              <span style={versionTextStyle}>Always Updated</span>
            </div>
          </div>
          <div>
  Want to contribute? Please contact me at{' '}
  <a href="mailto:triandi@natunakode.com?subject=I%20Want%20to%20Contribute" style={{ color: 'white' }}>
  triandi@natunakode.com
  </a>
</div>

        </>
      );
    } else {
      // Handle other links (if needed) or set a default message
      popupContent = "Coming Soon for " + content;
    }

    setPopupContent(popupContent);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent("Coming Soon");
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#11c5c6',
      border: '1px solid #ddd',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  const versionBoxStyle = {
    background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)',
    color: 'white',
    borderRadius: '20px',
    padding: '5px 3px',
    display: 'inline',
  };

  const versionTextStyle = {
    color: 'white',
  };

  return (
    <footer id="main-footer" className="bg-gray text-black px-6 py-8 md:px-12 rounded-t-3xl mt-20">
      <div className="border-t-2 border-gray-400 mb-4 pt-4"></div>
      <div className="flex flex-wrap flex-1 max-w-[800px]">
        <div className="w-6/12 md:flex-1 mb-4 md:mb-0 mt-4">
          <h1 className="font-montserrat font-bold md:text-lg mb-2">
            Features
          </h1>
          <ul>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text ml-3" href="/">
                Shorten Url
              </a>
            </li>
          </ul>
        </div>
        <div className="w-6/12 md:flex-1 mb-4 md:mb-0 mt-4">
          <h1 className="font-montserrat font-bold md:text-lg mb-2">Resources</h1>
          <ul>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text ml-2" href="/ReportLink">
                Report Link
              </a>
            </li>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text mr-6" href="https://wsu.natunakode.com/status">
                Status
              </a>
            </li>
          </ul>
        </div>
        <div className="w-6/12 md:flex-1 mb-4 md:mb-0 mt-4">
          <h1 className="font-montserrat font-bold md:text-lg mb-2 ml-5">Information</h1>
          <ul>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text ml-11" href="TermsOfService">
                Terms of Service
              </a>
            </li>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text ml-7" href="Privacy">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="w-6/12 md:flex-1 mb-4 md:mb-0 mt-4">
          <h1 className="font-montserrat font-bold md:text-lg mb-2 mr-5">Other</h1>
          <ul>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text mr-8" href="https://natunakode.com/">
                Blog
              </a>
            </li>
          </ul>
          <ul>
            <li className= "mb-3 text-sm md:text-base">
              <a className="hover:underline align-text mr-[--2]" href="/About">
                About Us
              </a>
            </li>
          </ul>
          <ul>
            <li className="mb-3 text-sm md:text-base">
              <a className="hover:underline align-text ml-3" href="/ContactUs">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="xl:-mb-3 flex items-center mb-20 flex-col md:flex-row">
  <div className="flex-1 ml-6 text-sm font-worksans mt-2.5">
    © wsus.me - {currentYear} Developed by:{' '}
    <a href="https://bio.triandi.natunakode.com" className="text-blue-500">
     Natuna Kode
    </a>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
      <div style={{ marginBottom: '10px' }}>
      <a href="#" onClick={() => openPopup("Version")} className="hover:underline text-blue-500">
          Version
        </a>
      </div>
      <a href="https://info.flagcounter.com/L9jb">
        <img src="https://s01.flagcounter.com/count2/L9jb/bg_FFFFFF/txt_051024/border_CCCCCC/columns_2/maxflags_4/viewers_0/labels_1/pageviews_1/flags_0/percent_0/" alt="Flag Counter" border="0" />
      </a>
    </div>
  </div>
</div>

      <Modal isOpen={isPopupOpen} onRequestClose={closePopup} className="popup-content">
        <Popup isOpen={isPopupOpen} content={popupContent} onClose={closePopup} />
      </Modal>
    </footer>
  );
}

export default Footer;
